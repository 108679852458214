/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

h5 {
    margin-bottom: 0px !important;
    margin-top: 15px;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
.p-label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    display: flex;
    align-items: center;

    color: #1B607B;

}

.p-container {
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #1B607B;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .p-container {
        font-size: 18px;
        width: 100%;
    }
    .p-container > li{
        flex-grow: 1;
    }
}

.p-container > li {
    padding-right: 10px;
}

.p-active {
    color: #33ADDC;
}

h4 {
    font-weight: 500 !important;
}

textarea, input, select, .MuiSelect-root {
    background-color: #f0f0f0 !important;
    padding: 5px !important;
}

#raw-input {
    background-color: transparent !important;
}

#material{
    background-color: transparent !important;
}

textarea {
    font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace !important;
}

h5 {
    font-size: 14px !important;
}

form > p {
    font-style: italic;
    font-size: 18px;
}

.react-pdf__Document{
    height: unset;
}

.MuiInputBase-adornedEnd{
    padding-right: 24px !important;
}
.MuiAutocomplete-endAdornment {
    background-color: #f0f0f0 !important;
}

.toolbar-style {
    padding-right: 85px !important;
}

/*!

 =========================================================
 * Material Dashboard React - v1.8.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  .badge-primary-background-color: #9c27b0;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}

